/* global
ClassicEditor
 autosize
 bsCustomFileInput
 checkRedirect
 initHtmlTextarea
 $x
 */


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// DATA TABLE

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      const $input = $table.querySelector("[data-multiple-select-item]");

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip();

        if ($input) {
          $input.$multipleSelectItem.reset();
        }
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      return html;
    },
  });
});

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item);
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

// re-enable all during modal-open disabled data-modal-links
function removeDisabledAfterModalOpened() {
  document.querySelectorAll("[data-modal-link][data-modal-link-disabled]").forEach(function ($el) {
    $el.classList.remove("disabled");
    $el.removeAttribute("data-modal-link-disabled");
  });
}

$x.beforeModalOpenDefault = function ($modal, $data, $el) {
  removeDisabledAfterModalOpened();

  if ($data.submit === "error") {
    if ($data.toaster) {
      $("body").toaster("updateToaster", $data.toaster);
    }
  }
};

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else if ($data.reload) {
          location.reload();
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  // set all data-modal-links (which are not already disabled) disabled -> so only one modal can be opened at a time
  document.querySelectorAll("[data-modal-link]:not(.disabled):not([disabled])").forEach(function ($el) {
    $el.setAttribute("data-modal-link-disabled", "data-modal-link-disabled");
    $el.classList.add("disabled"); // only allow data-modal-link to be clicked once until modal opened
  });

  $x.modal.open(
    this.href,
    {
      beforeModalOpen: $x.beforeModalOpenDefault,
      onModalOpen: $x.onModalOpenDefault,
    },
    this
  );
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

// ############################################################################
// institute map

$(".map-filter-field").on("change", function () {
  const form = $(this).parents("form");
  $.ajax({
    url: form[0].action,
    method: form[0].method,
    data: form.serialize(),
    dataType: "json",
    success: function (data) {
      $(window).trigger("leaflet_map_data_changed", [JSON.parse(data.geojson)]);
      $x.replaceHtml(data);
    },
  });
});

// #################################################################
// DATA-RICH-TEXT-EDITOR

var text_editor = null;
const text_editor_textarea = $("[data-rich-text-editor='custom-ckeditor']");
if (text_editor_textarea.length) {
  ClassicEditor.create(text_editor_textarea[0], JSON.parse(text_editor_textarea[0].dataset.ckeditorConfig)).then(editor => { text_editor = editor; });
}

// #################################################################
// EMAILS PAGE

$body.on("click", ".email-block", function () {
  const obj = $(this);
  const save_btn = $("#email-save-btn")[0];

  if (!text_editor) {
    return false;
  }

  if (obj.hasClass("active")) {
    obj.removeClass("active btn-secondary");
    $("#id_editemailsform-email_subject").val("");
    text_editor.setData("<p></p>");
    save_btn.href = "";
    $(save_btn).addClass("d-none");
  } else {
    $(".email-block").removeClass("active btn-secondary");
    obj.addClass("active btn-secondary");
    $.get(obj.data("url"), function (data) {
      $("#id_editemailsform-email_subject").val(data.subject);
      text_editor.setData(`${data.text}`);
    });
    save_btn.href = obj.data("url");
    $(save_btn).removeClass("d-none");
  }
  $("#id_email_error").addClass("d-none").html("");
});

$body.on("click", "#email-save-btn", function () {
  const obj = this;
  const editor = $(".ck-content");
  const subject_field = $("#id_editemailsform-email_subject");

  if (!obj.href) {
    $(obj).addClass("d-none");
    $(".email-block").removeClass("active btn-secondary");
    subject_field.val("");
    text_editor.setData("<p></p>");
    return false;
  }

  $.post(obj.href, { text: editor.html(), subject: subject_field.val() }, function (data) {
    if (data.error) {
      $("#id_email_error").removeClass("d-none").append(`<div class='alert alert-danger' role='alert'>${data.error}</div>`);
      subject_field.val(data.subject);
      text_editor.setData(`${data.text}`);
    } else {
      $("#id_email_error").addClass("d-none").html("");
    }
    if (data.toaster) {
      $body.toaster("updateToaster", data.toaster);
    }
  });
  return false;
});

// #################################################################
// EDIT PAGE

$body.on("click", ".edit-block", function () {
  const obj = $(this);
  const save_btn = $("#edit-save-btn")[0];

  if (!text_editor) {
    return false;
  }

  if (obj.hasClass("active")) {
    obj.removeClass("active btn-secondary");
    $("#id_editform-title").val("");
    text_editor.setData("<p></p>");
    save_btn.href = "";
    $(save_btn).addClass("d-none");
  } else {
    $(".edit-block").removeClass("active btn-secondary");
    obj.addClass("active btn-secondary");
    $.get(obj.data("url"), function (data) {
      $("#id_editform-title").val(data.title);
      text_editor.setData(`${data.text}`);
    });
    save_btn.href = obj.data("url");
    $(save_btn).removeClass("d-none");
  }
  $("#id_edit_error").addClass("d-none").html("");
});

$body.on("click", "#edit-save-btn", function () {
  const obj = this;
  const editor = $(".ck-content");
  const title_field = $("#id_editform-title");

  if (!obj.href) {
    $(obj).addClass("d-none");
    $(".edit-block").removeClass("active btn-secondary");
    title_field.val("");
    text_editor.setData("<p></p>");
    return false;
  }

  $.post(obj.href, { text: editor.html(), subject: title_field.val() }, function (data) {
    if (data.error) {
      $("#id_edit_error").removeClass("d-none").append(`<div class='alert alert-danger' role='alert'>${data.error}</div>`);
      title_field.val(data.title);
      text_editor.setData(`${data.text}`);
    } else {
      $("#id_edit_error").addClass("d-none").html("");
    }
    if (data.toaster) {
      $body.toaster("updateToaster", data.toaster);
    }
  });
  return false;
});


// ########################################################################
// CONTACT PAGE

// textarea characters counter
$body.on("keyup", "#id_contactform-msg", function () {
  const count = this.value.length;
  if (count > 800) {
    this.value = this.value.substring(0, 800);
  } else {
    $("#id_msg_characters_count").html(count.toString());
  }
});


// ########################################################################
// SPECIFICS PAGE

// textarea characters counter
$body.on("keyup", "#id_institutespecificsform-summary", function () {
  const count = this.value.length;
  $("#id_institutespecificsform-summary_count").html(count.toString());
});
$body.on("keyup", "#id_institutespecificsform-description_udg", function () {
  const count = this.value.length;
  $("#id_institutespecificsform-description_udg_count").html(count.toString());
});
$body.on("keyup", "#id_institutespecificsform-description_pdg", function () {
  const count = this.value.length;
  $("#id_institutespecificsform-description_pdg_count").html(count.toString());
});
$body.on("keyup", "#id_institutespecificsform-description_phd", function () {
  const count = this.value.length;
  $("#id_institutespecificsform-description_phd_count").html(count.toString());
});
$body.on("keyup", "#id_institutespecificsform-research_description", function () {
  const count = this.value.length;
  $("#id_institutespecificsform-research_description_count").html(count.toString());
});


// #################################################################
// Edit Topics PAGE

$body.on("click", ".topic-block", function () {
  const obj = $(this);
  const save_btn = $("#topic-save-btn")[0];

  $.get(obj.data("url"), function (data) {
    $("#id_edittopicsform-order").val(data.order);
    $("#id_edittopicsform-is_active").prop("checked", data.is_active);
    $("#id_edittopicsform-name").val(data.name);
    $("#id_edittopicsform-description").val(data.description);
  });
  save_btn.href = obj.data("url");
  $(save_btn).removeClass("d-none");
  $("#id_topic_error").addClass("d-none").html("");
});

$body.on("click", ".new-topic-block", function () {
  const obj = $(this);
  const save_btn = $("#topic-save-btn")[0];
  $("#id_edittopicsform-order").val("");
  $("#id_edittopicsform-is_active").prop("checked", true);
  $("#id_edittopicsform-name").val("");
  $("#id_edittopicsform-description").val("");
  save_btn.href = obj.data("url");
  $(save_btn).removeClass("d-none");
  $("#id_topic_error").addClass("d-none").html("");
});

$body.on("click", "#topic-save-btn", function () {
  const obj = this;
  const is_active = $("#id_edittopicsform-is_active");
  const order = $("#id_edittopicsform-order");
  const name = $("#id_edittopicsform-name");
  const description = $("#id_edittopicsform-description");

  if (!obj.href) {
    $(obj).addClass("d-none");
    is_active.prop("checked", false);
    order.val("");
    name.val("");
    description.val("");
    return false;
  }

  $.post(obj.href, { is_active: is_active.is(":checked"), order: order.val(), name: name.val(), description: description.val() }, function (data) {
    if (data.error) {
      $("#id_topic_error").removeClass("d-none").append(`<div class='alert alert-danger' role='alert'>${data.error}</div>`);
      is_active.prop("checked", data.is_active);
      order.val(data.order);
      name.val(data.name);
      description.val(data.description);
    } else {
      $("#id_topic_error").addClass("d-none").html("");
    }
    if (data.toaster) {
      $body.toaster("updateToaster", data.toaster);
    }
  });
  return false;
});
